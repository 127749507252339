import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Autocomplete, Box, Button, TextField } from '@mui/material'
import { BasicDialog } from 'components'

import { SelectSegmentType } from './selectPlan.types'
import { SegmentType } from 'types/segment.types'

const SelectSegment = ({
  defaultOpen,
  segments,
  setSegment,
  variant = 'segmento',
}: SelectSegmentType) => {
  const navigate = useNavigate()
  const [value, setValue] = useState<string>()
  const [open, setOpen] = useState(defaultOpen)

  const onClose = () => {
    setOpen(false)
    if (!value) return goBack()
    setSegment(value)
  }

  const handleChange = (value: SegmentType | null) => {
    if (variant === 'segmento') {
      return setValue(value?.name)
    }

    setValue(value?.id?.toString())
  }

  const goBack = () => navigate(-1)

  return (
    <BasicDialog
      open={open}
      disableBackdropClick
      onClose={onClose}
      title={`Selecione um ${variant}`}
      dialogActions={
        <Box display="flex" gap={2}>
          <Button variant="outlined" onClick={goBack}>
            Voltar
          </Button>
          <Button disabled={!value} variant="contained" onClick={onClose}>
            Avançar
          </Button>
        </Box>
      }
    >
      <Box>
        <Autocomplete
          options={segments || []}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} margin="dense" />}
          onChange={(_, value) => handleChange(value)}
        />
      </Box>
    </BasicDialog>
  )
}

export default SelectSegment
