import { Grid as GridMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { InferType, TLength } from 'types/materialUI.types'

type GridType = {
  $alignItems?: Property.AlignItems
  $display?: Property.Display
  $justifyContent?: Property.JustifyContent
  $order?: Property.Order
  $my?: Property.MarginBlock<TLength>
}

interface GridWithValidationsType extends GridType {
  $lgDown?: InferType<GridType>
  $mdDown?: InferType<GridType>
  $smDown?: InferType<GridType>
  $xlDown?: InferType<GridType>
}

const Grid = styled(GridMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<GridWithValidationsType>(
  ({
    $alignItems,
    $display,
    $justifyContent,
    $order,
    $my,
    $smDown,
    $mdDown,
    $lgDown,
    $xlDown,
    theme,
  }) => ({
    order: $order,
    display: $display,
    justifyContent: $justifyContent,
    alignItems: $alignItems,
    marginBlock: $my && theme.spacing($my),
    [theme.breakpoints.down('sm')]: {
      order: $smDown?.order,
      justifyContent: $smDown?.justifyContent,
      alignItems: $smDown?.alignItems,
    },
    [theme.breakpoints.down('md')]: {
      order: $mdDown?.order,
      display: $mdDown?.display,
    },
    [theme.breakpoints.down('lg')]: {
      order: $lgDown?.order,
      marginBlock: $lgDown?.my && theme.spacing($lgDown?.my),
    },
    [theme.breakpoints.down('xl')]: {
      order: $xlDown?.order,
    },
  })
)

export default Grid
