import { UpgradePlansType } from 'types/integrationPartner.types'
import { isString } from 'lodash'

import formatters from './formatters'
import plan from 'constants/plan'

const mountAdvantages = (upgradePlan?: UpgradePlansType) => {
  if (!upgradePlan) return []

  const config =
    upgradePlan?.cookiePlan === 'custom'
      ? upgradePlan?.config
      : plan.COOKIE_PLANS_ADVANTAGES[upgradePlan?.cookiePlan]
  const domains = config?.bannersLimit || 1
  const requestsLimit = isString(config?.requestsLimit)
    ? config?.requestsLimit
    : formatters.numberToStringWithDots(config?.requestsLimit ?? 50000)

  return [
    {
      name: config?.automaticScan
        ? 'Scanner mensal de cookies'
        : 'Scanner de cookies',
    },
    {
      name: `${requestsLimit} acessos`,
    },
    {
      name: `${domains} domínio${domains > 1 ? 's' : ''}`,
    },
  ]
}

export default mountAdvantages
