import { UpgradePlansType } from 'types/integrationPartner.types'
import { BannerPlanCardType } from 'views/Banner/components/BannerPlanCard/bannerPlanCard.types'

import numberToStringWithDots from './formatters/numberToStringWIthDots'

import routes from 'constants/routes'
import plan from 'constants/plan'
import {
  find,
  isString,
  // reject
} from 'lodash'

const DPONET_UPGRADE: BannerPlanCardType = {
  buttonText: 'Consultar',
  name: 'Plano DPOnet',
  price: 'A consultar',
  height: '40vh',
  priceKind: 'free',
  accessLimit: '1.000.000',
  buttonKind: 'contract',
  domains: 10,
  recommended: true,
}

const mountUpgradePlans = (
  upgradePlans: UpgradePlansType[],
  currentPlanId?: number
): BannerPlanCardType[] => {
  const currentPlan = find(upgradePlans, { plan: { id: currentPlanId } })
  // const upgradablePlans = reject(
  //   upgradePlans,
  //   (upgradePlan) =>
  //     parseFloat(upgradePlan?.plan?.price ?? 0) <
  //     parseFloat(currentPlan?.plan?.price ?? '59.9')
  // )

  const plans: BannerPlanCardType[] = upgradePlans.map((upgradePlan, index) => {
    const acquired = upgradePlan?.plan?.id === currentPlan?.plan?.id
    const buttonText = acquired ? 'CONTRATADO' : 'COMEÇAR'
    const priceKind =
      parseFloat(upgradePlan.plan.price) > 0 ? 'contract' : 'free'
    const buttonKind = acquired ? 'active' : 'contract'
    const indexRef = index + 1
    const borderRight =
      upgradePlans.length == 1 || (indexRef < 4 ? index !== 0 : index % 3 !== 0)

    const config =
      upgradePlan.cookiePlan === 'custom'
        ? upgradePlan?.config
        : plan.COOKIE_PLANS_ADVANTAGES[upgradePlan.cookiePlan]

    const disabled =
      !!currentPlan &&
      parseFloat(upgradePlan?.plan?.price ?? 0) <
        parseFloat(currentPlan?.plan?.price ?? '59.9')

    return {
      buttonText,
      priceKind,
      buttonKind,
      price: upgradePlan.plan.price,
      borderRight,
      disabled,
      borderLeft: indexRef % 3 !== 0,
      height: index === 1 || index % 3 === 1 ? '60vh' : '40vh',
      accessLimit: isString(config?.requestsLimit)
        ? config?.requestsLimit
        : numberToStringWithDots(config?.requestsLimit ?? 50000),
      domains: config?.bannersLimit || 1,
      name: upgradePlan?.name,
      route: routes.checkout.upgrade,
      planId: upgradePlan?.plan?.id,
    }
  })

  const numPlans = plans.length
  const isFilled = (numPlans - 1) % 3 !== 1

  return [
    ...plans,
    {
      ...DPONET_UPGRADE,
      borderLeft: isFilled,
      height: numPlans > 3 && isFilled ? '60vh' : '40vh',
      route: routes.checkout.all,
    },
  ]
}

export default mountUpgradePlans
