import { HTMLInputTypeAttribute } from 'react'
import { WelcomePlanFormControlType } from 'views/WelcomeSteps/steps/Plan/schema'

import hello from 'images/hello.png'
import find from 'images/find.png'
import policies from 'images/policies.png'
import stampStep from 'images/stamp-step.png'
import userInterface from 'images/user_interface.png'

const STEPS = [
  {
    image: hello,
    isForm: true,
    text: 'Bem-vindo ao DPOeasy, a sua plataforma de gestão da LGPD simplificada. Vamos embarcar juntos em uma rápida jornada para nos conhecermos melhor. Vamos começar?',
  },
  {
    image: find,
    isForm: false,
    text: 'Cookies são pequenos arquivos de texto usados por sites para armazenar informações sobre a atividade do usuário, personalizando a experiência de navegação e oferecendo funcionalidades específicas.',
  },
  {
    image: policies,
    isForm: false,
    text: 'Com base nas suas informações nossa Inteligência Artificial está gerando uma Política de Cookies e um Aviso de Privacidade para sua organização.',
  },
  {
    image: stampStep,
    isForm: false,
    text: 'Um selo para os titulares de dados facilita a comunicação com a organização, evidenciando conformidade com regulamentações de proteção de dados, promovendo transparência e confiança na gestão das informações pessoais.',
  },
  {
    image: userInterface,
    isForm: true,
    text: 'Nesta etapa, você fornecerá as informações de pagamento, como número do cartão, data de validade e código de segurança, além de escolher o plano que melhor se adapta às suas necessidades.',
    leftBoxDisplay: 'none',
    padding: 0,
  },
]

const BASE_DPOEASY_ADVANTAGES = [
  {
    name: 'Canal de Atendimento',
  },
  {
    name: 'Banner de Cookies',
  },
  {
    name: 'Políticas',
  },
]

const DPOEASY_ADVANTAGES = {
  free: [
    ...BASE_DPOEASY_ADVANTAGES,
    {
      name: 'Scanner de cookies',
    },
    {
      name: '50 mil acessos',
    },
    {
      name: '1 domínio',
    },
    {
      name: 'Respostas Automáticas',
    },
  ],
  limited: [
    ...BASE_DPOEASY_ADVANTAGES,
    {
      name: 'Scanner de cookies',
    },
    {
      name: '100 mil acessos',
    },
    {
      name: '1 domínio',
    },
    {
      name: 'Respostas Automáticas',
    },
  ],
  unlimited: [
    ...BASE_DPOEASY_ADVANTAGES,
    {
      name: 'Scanner mensal de cookies',
    },
    {
      name: 'Atualização automática de políticas',
    },
    {
      name: '1 milhão acessos',
    },
    {
      name: '1+ domínios',
    },
    {
      name: 'Respostas Automáticas',
    },
  ],
  custom: [],
}

const COMPANY_LOGO_RADIO_BUTTON_CONTROL_ITEMS = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
]

const USER_DATA_TERMS_CONTROL_ITEMS: UserDataControlItems[] = [
  {
    controlName: 'privacyCheck',
    label: 'Li e concordo com o Aviso de privacidade',
  },
  {
    controlName: 'usingThermsCheck',
    label: 'Li e concordo com os Termos e condições de uso',
  },
]

type UserDataControlItems = {
  controlName: WelcomePlanFormControlType
  label: string
  type?: HTMLInputTypeAttribute
  gridLg?: number
}

export default {
  STEPS,
  BASE_DPOEASY_ADVANTAGES,
  DPOEASY_ADVANTAGES,
  COMPANY_LOGO_RADIO_BUTTON_CONTROL_ITEMS,
  USER_DATA_TERMS_CONTROL_ITEMS,
}
