import { InputAdornment, TextField } from '@mui/material'
import { Search as SearchIcon } from 'react-feather'
import { SearchBoxType } from './SearchBox.types'

const SearchBox = ({
  faqs,
  searchInput,
  setSearchInput,
  setValueInput,
  setFilteredResults,
}: SearchBoxType) => {
  const searchItems = (searchValue: string) => {
    setSearchInput(searchValue)
    setValueInput(searchValue)
    if (searchInput) {
      const filteredData = faqs.filter((item: object) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      })
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(faqs)
    }
  }

  return (
    <TextField
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon size={20} />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      onChange={(e) => searchItems(e.target.value)}
    />
  )
}

export default SearchBox
