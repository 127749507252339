import { useEffect, useRef, useState } from 'react'
import { isEmpty } from 'lodash'

import { FormHelperText } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'

import { useAuth, useFetch } from 'hooks'
import { yupResolver } from '@hookform/resolvers/yup'

import { PaymentContractType } from 'types/paymentOrder.types'
import { UpgradeFormType } from './upgradeForm.types'

import { LoadingTrails, MUI } from 'components'
import { AddressSection, PaymentSection } from '../CheckoutForm/components'

import service from 'service'
import { getGoogleRecaptchaToken } from 'service/env'
import { formatters } from 'helpers'

import { StandardLabelType } from 'constants/cardFlags'
import { CheckoutSchemaType } from 'constants/checkout/form'

import constants from 'constants/index'
import schema from './schema'

const UpgradeForm = ({
  upgradePlan,
  setTrailId,
  setErrorTitle,
}: UpgradeFormType) => {
  const { userCompany } = useAuth()
  const address = userCompany?.company?.address

  const navigate = useNavigate()
  const inputRef = useRef<HTMLButtonElement>(null)

  const [brand, setBrand] = useState<StandardLabelType>('Visa')
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState,
    register,
    ...form
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      planId: upgradePlan?.plan?.id?.toString(),
      address: {
        street: address?.street ?? '',
        number: address?.number ?? '',
        neighborhood: address?.neighborhood ?? '',
        complement: address?.complement ?? '',
        postalCode: formatters.cep.format(address?.postalCode) ?? '',
        city: address?.city ?? '',
        state: address?.state ?? '',
      },
      card: {
        name: '',
        number: '',
        cvv: '',
        expiration: '',
      },
      recaptcha: '',
    },
  })

  useEffect(() => {
    register('card.id')
    register('typeCard')
  }, [register])

  const { response: responseContracts, loading: isLoadingContracts } = useFetch(
    service.dponet.financialPanel.listContracts,
    {},
    []
  )

  const paymentContracts: PaymentContractType[] =
    responseContracts?.data?.paymentContracts
  const creditCards = paymentContracts?.map(
    (paymentContract) => paymentContract.creditCard
  )

  useEffect(() => {
    if (!isLoadingContracts && isEmpty(creditCards)) {
      setValue('typeCard', true)
    }
    //eslint-disable-next-line
  }, [isLoadingContracts])

  const handleClickBack = () => navigate(-1)

  const resetRecaptcha = () => {
    recaptchaRef?.current?.reset()
    expiredRecaptcha()
  }

  const expiredRecaptcha = () => setValue('recaptcha', '')

  const handleRecaptcha = (token: string | null) => {
    if (token) setValue('recaptcha', token)
  }

  const recaptcha = watch('recaptcha')

  const onSubmit = async (data: object) => {
    try {
      setTrailId('processing')
      const response = await service.dponet.preRegistration.upgrade({
        preRegistration: {
          ...data,
          card: {
            ...(data as CheckoutSchemaType).card,
            brand,
          },
        },
      })
      const status = response?.data?.status
      switch (status) {
        case constants.paymentOrder.FINANCIAL_PAID_STATUS:
          setTrailId('success')
          setTimeout(location.reload, 3500)
          break
        case constants.paymentOrder.FINANCIAL_PRE_CAPTURED_STATUS:
          setTrailId('pre_captured')
          setTimeout(() => navigate(-1), 5500)
          break
        default:
          setTrailId('error')
          setErrorTitle('Ocorreu um erro ao processar o pagamento!')
          break
      }
    } catch (e) {
      setTrailId('error')
      setErrorTitle(formatters.errorMessage(e))
    }
    resetRecaptcha()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider
        control={control}
        setValue={setValue}
        watch={watch}
        handleSubmit={handleSubmit}
        register={register}
        formState={formState}
        {...form}
      >
        <LoadingTrails open={isLoadingContracts} variant="white" />
        <MUI.Box display="flex" flexDirection="column" gap={4}>
          <AddressSection inputRef={inputRef} />
          <PaymentSection setBrand={setBrand} creditCards={creditCards} />
        </MUI.Box>
        <MUI.Box
          display="flex"
          justifyContent="space-between"
          $mdDown={{ flexDirection: 'column' }}
          gap={6}
          my={4}
        >
          <Controller
            render={({ field }) => (
              <MUI.FormControl {...field}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={getGoogleRecaptchaToken()}
                  onChange={(token) => handleRecaptcha(token)}
                  onExpired={expiredRecaptcha}
                  size="normal"
                />
                {!!formState.errors.recaptcha && (
                  <FormHelperText error={!!formState.errors.recaptcha}>
                    <>{formState?.errors?.recaptcha?.message}</>
                  </FormHelperText>
                )}
              </MUI.FormControl>
            )}
            control={control}
            name="recaptcha"
          />
          <MUI.Grid container spacing={6} justifyContent="end" alignItems="end">
            <MUI.Grid item xs={6} md={3}>
              <MUI.Button
                variant="outlined"
                size="large"
                fullWidth
                onClick={handleClickBack}
                $backgroundColor="white"
              >
                Voltar
              </MUI.Button>
            </MUI.Grid>
            <MUI.Grid item xs={6} md={3}>
              <MUI.Button
                variant="contained"
                size="large"
                type="submit"
                fullWidth
                disabled={!recaptcha}
              >
                Confirmar
              </MUI.Button>
            </MUI.Grid>
          </MUI.Grid>
        </MUI.Box>
      </FormProvider>
    </form>
  )
}

export default UpgradeForm
