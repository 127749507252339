import { SyntheticEvent, useState } from 'react'
import { Tabs } from '@mui/material'

import {
  LoadingFeedback,
  MUI,
  PageHead,
  StandardPageStructure,
} from 'components'

import { formatters, validates } from 'helpers'

import { useAuth, useFetch } from 'hooks'

import { StampItemTabsType } from 'constants/serviceChannel'

import service from 'service'
import constants from 'constants/index'

const Stamps = () => {
  const [selectedTab, setSelectedTab] = useState(
    constants.serviceChannel.GENERAL_STAMPS_ID as StampItemTabsType
  )

  const { userCompany } = useAuth()

  const { response, loading } = useFetch(
    service.dponet.company.consultQRCode,
    {
      token: userCompany?.company?.token,
    },
    [selectedTab],
    selectedTab === 'print',
    true
  )

  const { response: responseStamps, loading: loadingStamps } = useFetch(
    service.dponet.privacyPortal.stamps,
    {},
    []
  )

  const { response: responseConsult, loading: loadingConsult } = useFetch(
    service.dponet.privacyPortal.consult,
    {},
    [selectedTab],
    selectedTab === 'emailSignature',
    true
  )
  const isMailTab = selectedTab === 'emailSignature'

  const printingStamp = formatters.baseURL(
    userCompany?.company?.impressionStamp
  )
  const qrCode = formatters.baseURL(response?.data?.object)

  const StepContent = validates.serviceChannel.stepsContent(selectedTab)

  const handleTabChange = (_: SyntheticEvent, clickedTab: StampItemTabsType) =>
    setSelectedTab(clickedTab)

  return (
    <StandardPageStructure>
      <LoadingFeedback open={loading || loadingStamps || loadingConsult} />
      <PageHead name="Meios de comunicação" />
      <MUI.Paper
        variant="outlined"
        $borderBottom={0}
        $borderBottomLeftRadius={0}
        $borderBottomRightRadius={0}
      >
        <Tabs
          value={selectedTab}
          variant="scrollable"
          onChange={handleTabChange}
        >
          {constants.serviceChannel.STAMPS_ITEMS_TABS.map((tab) => (
            <MUI.Tab
              key={tab.id}
              label={tab.tabName}
              value={tab.id}
              $minWidth={170}
            />
          ))}
        </Tabs>
      </MUI.Paper>
      {selectedTab && (
        <StepContent
          qrCode={qrCode}
          stampURL={
            isMailTab ? responseConsult?.data?.portalUrl : printingStamp
          }
          tag={responseStamps?.data?.blue || ''}
        />
      )}
    </StandardPageStructure>
  )
}

export default Stamps
