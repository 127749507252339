import { MUI } from 'components'

import { useMediaQuery } from 'hooks'

import { BannerPlanCardType } from './bannerPlanCard.types'
import { Check } from 'react-feather'
import { theme } from 'theme'
import { useNavigate } from 'react-router-dom'
import { formatters } from 'helpers'

const BannerPlanCard = ({
  buttonText,
  name,
  price,
  height,
  accessLimit,
  route,
  planId,
  disabled = false,
  nIndex = 0,
  domains = 1,
  borderLeft = true,
  borderRight = true,
  recommended = false,
  priceKind = 'contract',
  buttonKind = 'contract',
}: BannerPlanCardType) => {
  const navigate = useNavigate()

  const handleNavigate = () => {
    if (route) {
      navigate(route, { state: { planId } })
    }
  }
  const { down } = useMediaQuery()

  const baseHeight = height ?? '60vh'
  const recommendBaseSize = '13.5px'
  const cardHeight = recommended
    ? `calc(${baseHeight} - ${recommendBaseSize})`
    : baseHeight

  return (
    <MUI.Grid item xs={12} md={4}>
      <MUI.Box
        mt={nIndex > 2 ? 6 : 0}
        sx={{
          borderTop: 3,
          borderBottom: 3,
          ...(down.sm
            ? {
                borderRadius: 3,
                border: `2px solid ${theme.palette.tertiary.main}`,
              }
            : {
                borderLeft: borderLeft ? 3 : 0,
                borderRight: borderRight ? 3 : 0,
                borderTopRightRadius: borderRight ? 10 : 0,
                borderBottomRightRadius: borderRight ? 10 : 0,
                borderTopLeftRadius: borderLeft ? 10 : 0,
                borderBottomLeftRadius: borderLeft ? 10 : 0,
                borderColor: theme.palette.tertiary.main,
              }),
        }}
      >
        {recommended && (
          <MUI.Box
            display="flex"
            justifyContent="center"
            width="100%"
            mt={`-${recommendBaseSize}`}
          >
            <MUI.Chip
              $fontWeight="700"
              $height={27}
              $borderRadius={13.5}
              color="tertiary"
              label="RECOMENDADO"
            />
          </MUI.Box>
        )}
        <MUI.Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          p={8}
          height={cardHeight}
        >
          <MUI.Box
            display="flex"
            $flexDirection="column"
            $alignItems="center"
            mt={recommended ? `-${recommendBaseSize}` : 0}
          >
            <MUI.Typography gutterBottom variant="h6">
              {name}
            </MUI.Typography>
            {priceKind === 'free' ? (
              <MUI.Typography variant="h6">{price}</MUI.Typography>
            ) : (
              <MUI.Typography variant="h6">
                R$ {formatters.formatToCurrencyBrl(price)} /mês
              </MUI.Typography>
            )}
            <MUI.Box
              mt={4}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <MUI.Box mr={2}>
                <Check size={22} color={theme.palette.tertiary.main} />
              </MUI.Box>
              <MUI.Typography variant="subtitle1">
                {domains} site{domains > 1 ? 's' : ''}
              </MUI.Typography>
            </MUI.Box>
            <MUI.Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <MUI.Box mr={2}>
                <Check size={22} color={theme.palette.tertiary.main} />
              </MUI.Box>
              <MUI.Typography variant="subtitle1">
                Até {accessLimit} acessos
              </MUI.Typography>
            </MUI.Box>
          </MUI.Box>
          {buttonKind === 'contract' ? (
            <MUI.Button
              $fontWeight="700 !important"
              variant="contained"
              color={disabled ? 'primary' : 'tertiary'}
              onClick={handleNavigate}
              disabled={disabled}
              fullWidth
            >
              {buttonText}
            </MUI.Button>
          ) : (
            <MUI.Typography fontWeight="700">CONTRATADO</MUI.Typography>
          )}
        </MUI.Box>
      </MUI.Box>
    </MUI.Grid>
  )
}

export default BannerPlanCard
