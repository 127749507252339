import { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Store as StoreIcon } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'

import { MUI } from 'components'

import { ProductCardType } from './productCard.types'

const ProductCard = ({ partnerService }: ProductCardType) => {
  const [imageError, setImageError] = useState(false)

  const handleImageError = () => setImageError(true)

  return (
    <Grid container spacing={4} textAlign="justify" alignItems="center" p={4}>
      <MUI.Grid item xs={12} lg={4} xl={3.5} $lgDown={{ my: 16 }}>
        <Box display="flex" justifyContent="center">
          {imageError || !partnerService?.partner?.logo ? (
            <StoreIcon color="primary" sx={{ fontSize: 92 }} />
          ) : (
            <MUI.Img
              src={partnerService?.partner?.logo ?? ''}
              alt={`Logo ${partnerService?.partner?.name}`}
              $maxWidth={170}
              $alignContent="center"
              $textAlign="center"
              onError={handleImageError}
            />
          )}
        </Box>
      </MUI.Grid>
      <Grid
        item
        xs={12}
        lg={8}
        xl={8.5}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="100%"
        gap={4}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography fontWeight={600} color="primary">
            {partnerService?.companyService?.serviceCategory?.name}
            &nbsp;-&nbsp;
            {partnerService?.companyService?.name}
          </Typography>
          <Box height={130}>
            <PerfectScrollbar>
              <Typography variant="body2" color="primary" pr={4}>
                {partnerService?.description}
              </Typography>
            </PerfectScrollbar>
          </Box>
        </Box>
        {partnerService?.partner && (
          <Typography variant="body2" color="primary">
            Oferecido por:&nbsp;
            <strong>{partnerService?.partner?.tradeName}</strong>
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default ProductCard
