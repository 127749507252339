import { Controller, useFormContext } from 'react-hook-form'
import {
  Box,
  Grid,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import { MUI } from 'components'

import { BannerConfigurationSchemaType } from 'types/banner.types'
import { ScannerCardType } from './scannerCard.types'

import { theme } from 'theme'

import { TYPE_CATEGORIES } from 'constants/cookie'
import constants from 'constants/index'

const ScannerCard = ({ cookies }: ScannerCardType) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<BannerConfigurationSchemaType>()

  return (
    <Grid container spacing={2}>
      {cookies?.map((cookie, index) => {
        const disableInput =
          cookie?.category?.id === constants.cookie.NECESSARY_CATEGORY_ID

        const categoryId = watch(
          `cookies.${index}.categoryId`
        ) as TYPE_CATEGORIES

        return (
          <Grid item xs={12} key={index}>
            <Box
              bgcolor={theme.palette.background.default}
              border={1}
              borderColor={theme.palette.primary.main}
              borderRadius={1}
            >
              <Grid container p={4} spacing={6}>
                <Grid item xs={12} sm={6} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      color="primary"
                      variant="body2"
                      fontWeight={600}
                      gutterBottom
                    >
                      Plataforma de origem:
                    </Typography>
                    <Controller
                      render={({ field }) => (
                        <Tooltip
                          title={
                            disableInput &&
                            'Cookies necessários não podem ser modificados'
                          }
                        >
                          <TextField
                            {...field}
                            placeholder="Origem"
                            error={!!errors?.cookies?.[index]?.platform}
                            helperText={errors?.cookies?.[
                              index
                            ]?.platform?.message?.toString()}
                            type="text"
                            size="small"
                            disabled={disableInput}
                            fullWidth
                          />
                        </Tooltip>
                      )}
                      control={control}
                      name={`cookies.${index}.platform`}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      color="primary"
                      variant="body2"
                      fontWeight={600}
                      gutterBottom
                    >
                      Cookie:
                    </Typography>
                    <TextField
                      defaultValue={cookie?.name}
                      size="small"
                      type="text"
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography
                    color="primary"
                    variant="body2"
                    fontWeight={600}
                    gutterBottom
                  >
                    Classificação:
                  </Typography>
                  <Controller
                    render={({ field }) => (
                      <Tooltip
                        title={
                          disableInput &&
                          'Cookies necessários não podem ser modificados'
                        }
                      >
                        <Select
                          {...field}
                          fullWidth
                          error={!!errors?.cookies?.[index]?.categoryId}
                          size="small"
                          placeholder="Selecionar"
                          disabled={disableInput}
                        >
                          {constants.cookie.CATEGORY_OPTIONS.map(
                            (cookieCategory) => (
                              <MUI.MenuItem
                                key={cookieCategory?.id}
                                value={cookieCategory?.id}
                                disabled={cookieCategory?.disabled}
                                $textTransform="initial"
                              >
                                {cookieCategory.name}
                              </MUI.MenuItem>
                            )
                          )}
                        </Select>
                      </Tooltip>
                    )}
                    control={control}
                    name={`cookies.${index}.categoryId`}
                  />
                  {!!errors?.cookies?.[index] && (
                    <Typography variant="caption" color="error">
                      {errors?.cookies?.[
                        index
                      ]?.categoryId?.message?.toString()}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Typography
                    color="primary"
                    variant="body2"
                    fontWeight={600}
                    gutterBottom
                  >
                    Tag manager:
                  </Typography>
                  <TextField
                    type="text"
                    size="small"
                    value={constants.cookie.STORAGE_TYPES?.[categoryId]}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ScannerCard
