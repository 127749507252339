import { CardContent, Container, Dialog } from '@mui/material'

import { Close as CloseIcon } from '@mui/icons-material'

import { MUI } from 'components'

import { useAuth, useMediaQuery } from 'hooks'
import { BannerUpgradeDialogType } from './bannerUpgradeDialog.types'

import { BannerPlanCard } from '..'
import { mountUpgradePlans } from 'helpers'

const BannerUpgradeDialog = ({ open, setOpen }: BannerUpgradeDialogType) => {
  const { down } = useMediaQuery()
  const { userCompany } = useAuth()

  const PLAN_ITEMS = mountUpgradePlans(
    userCompany?.company?.integrationPartner?.upgradePlans ?? [],
    userCompany?.company?.planId
  )

  const handleClose = () => setOpen(false)

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      onClose={handleClose}
      fullScreen={down.md || PLAN_ITEMS.length > 3}
      fullWidth
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.primary.main,
          minHeight: '80vh',
        }),
      }}
    >
      <MUI.Box p={1} display="flex" justifyContent="flex-end" width="100%">
        <MUI.IconButton
          $color="white"
          aria-label="Fechar"
          onClick={handleClose}
        >
          <CloseIcon />
        </MUI.IconButton>
      </MUI.Box>
      <CardContent component={Container} maxWidth="md">
        <MUI.Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          color="white"
          mb={10}
        >
          <MUI.Typography variant="h3" gutterBottom textAlign="center">
            Deseja liberar mais acessos?
          </MUI.Typography>
          <MUI.Typography variant="h6" lineHeight={1.3} textAlign="center">
            Atualmente seu plano permite até 50.000 acessos em seu site.
          </MUI.Typography>
          <MUI.Typography variant="h6" lineHeight={1.3} textAlign="center">
            Verifique nossos planos para aumentar o seu número de acessos.
          </MUI.Typography>
        </MUI.Box>
        <MUI.Grid
          container
          spacing={down.sm || PLAN_ITEMS.length === 2 ? 4 : 0}
          justifyContent={PLAN_ITEMS.length < 3 ? 'center' : 'inherit'}
          alignItems="center"
          color="white"
        >
          {PLAN_ITEMS.map((plan, index) => (
            <BannerPlanCard {...plan} key={plan.name} nIndex={index} />
          ))}
        </MUI.Grid>
      </CardContent>
    </Dialog>
  )
}

export default BannerUpgradeDialog
