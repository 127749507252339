import { Fragment, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { isNil } from 'lodash'
import { visuallyHidden } from '@mui/utils'

import {
  CheckoutForm,
  CheckoutPageStructure,
  CheckoutTrails,
} from './components'
import { useAuth, useFetch } from 'hooks'

import { CheckoutTrailId } from 'constants/checkout/trails'

import { SegmentType } from 'types/segment.types'
import service from 'service'
import SelectSegment from './components/SelectPlan'

const CheckoutMain = () => {
  const [trailId, setTrailId] = useState<CheckoutTrailId | null | undefined>(
    null
  )
  const { userCompany } = useAuth()
  const [segment, setSegment] = useState<string>()
  const [errorTitle, setErrorTitle] = useState<string>()
  const [segments, setSegments] = useState<SegmentType[]>()

  const { response: responsePreRegistration, loading: loadingPreRegistration } =
    useFetch(
      service.dponet.preRegistration.show,
      {},
      [userCompany?.company?.preRegistrationCreated],
      !!userCompany?.company?.preRegistrationCreated
    )

  const preRegistration = responsePreRegistration?.data?.preRegistration
  const segmentName = useMemo(
    () => preRegistration?.segment?.name || userCompany?.company?.segment?.name,
    [userCompany, preRegistration]
  )

  useEffect(() => {
    const loadSegments = async () => {
      const response = await service.dponet.segment.get({ perPage: 999 })

      setSegments(response?.data?.segments)
    }

    if (!loadingPreRegistration) {
      if (!segmentName) {
        loadSegments()
      } else {
        setSegment(segmentName)
      }
    }
  }, [segmentName, loadingPreRegistration])

  return (
    <Fragment>
      <CheckoutTrails
        trailId={trailId}
        setTrailId={setTrailId}
        style={isNil(trailId) ? visuallyHidden : undefined}
        errorTitle={errorTitle}
      />
      <CheckoutPageStructure
        style={!isNil(trailId) ? visuallyHidden : undefined}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={8}
          height="100%"
          justifyContent="center"
        >
          {!!segment && !loadingPreRegistration && (
            <CheckoutForm
              segment={segment}
              preRegistration={responsePreRegistration?.data?.preRegistration}
              setTrailId={setTrailId}
              setErrorTitle={setErrorTitle}
            />
          )}
        </Box>
        {!loadingPreRegistration && (
          <SelectSegment
            setSegment={setSegment}
            defaultOpen={!segmentName}
            segments={segments}
          />
        )}
      </CheckoutPageStructure>
    </Fragment>
  )
}

export default CheckoutMain
