import { Fragment } from 'react'
import { Button } from '@mui/material'
import { ExternalLink as ExternalLinkIcon } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import routes from 'constants/routes'

const HelpBox = () => {
  const navigate = useNavigate()

  const goToFaqsDocumentations = () => {
    navigate(routes.faqs.all)
  }

  return (
    <Fragment>
      <Button
        variant="contained"
        color="tertiary"
        endIcon={<ExternalLinkIcon size="14" />}
        onClick={goToFaqsDocumentations}
      >
        Central de ajuda
      </Button>
    </Fragment>
  )
}

export default HelpBox
