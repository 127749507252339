import { isEmpty } from 'lodash'

import { FiltersTypes } from 'types/filters.types'

const autoCompleteOptions = (data: FiltersTypes[], nameKey = 'name') => {
  if (isEmpty(data)) return []

  return data?.map((option) => ({ id: option.id, name: option[nameKey] }))
}

export default autoCompleteOptions
