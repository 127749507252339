import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Archive as ArchiveIcon,
  // File as FileIcon,
  Home as HomeIcon,
  Shield as ShieldIcon,
  ShoppingCart as ShoppingCartIcon,
} from 'react-feather'
import { Box, List } from '@mui/material'
import { Cookie as CookieIcon } from '@mui/icons-material'

import { HelpBox, ListItem, MenuItemButton } from './components'
// import { Permitted } from 'components'

// import { usePermission } from 'hooks'

import { MenuItemType } from 'types/menuItems.types'

import routes from 'constants/routes'

// import menuItems from 'constants/menuItems'

const Items = () => {
  // const { loaded } = usePermission()

  // if (!loaded) return <></>

  const menuItems: MenuItemType[] = [
    {
      Icon: HomeIcon,
      title: 'Home',
      url: routes.root,
      children: false,
    },

    {
      Icon: ShieldIcon,
      title: 'Canal de Atendimento',
      url: routes.tickets.all,
      children: false,
      childrens: [
        {
          title: 'Respostas automáticas',
          url: routes.serviceChannel.automaticAnswer,
          children: true,
        },
        // {
        //   title: 'Configurações do tema',
        //   url: '/',
        //   children: true,
        // },
        {
          title: 'Solicitação de titulares',
          url: routes.tickets.all,
          children: true,
          subpages: [routes.tickets.show],
        },
        {
          title: 'Meios de comunicação',
          url: routes.serviceChannel.stamps,
          children: true,
        },
      ],
    },
    {
      Icon: CookieIcon,
      title: 'Cookies',
      url: routes.banners.all,
      children: false,
      childrens: [
        {
          title: 'Meus banners',
          url: routes.banners.all,
          subpages: [routes.banners.settings],
          children: true,
        },
        {
          title: 'Consentimentos',
          url: routes.consents.all,
          children: true,
        },
      ],
    },
    {
      Icon: ArchiveIcon,
      title: 'Políticas',
      url: routes.policies.all,
      subpages: [routes.policies.new, routes.policies.edit],
      children: false,
    },
    {
      Icon: ShoppingCartIcon,
      title: 'Marketplace',
      url: routes.marketplace.all,
      children: false,
      childrens: [
        {
          title: 'Início',
          url: routes.marketplace.all,
          children: true,
        },
        // {
        //   title: 'Produtos e Serviços',
        //   url: routes.marketplace.products,
        //   children: true,
        // },
        {
          title: 'Meus pedidos',
          url: routes.marketplace.orders,
          children: true,
        },
      ],
    },
    // {
    //   Icon: FileIcon,
    //   title: 'Relatórios',
    //   url: '/app',
    //   children: false,
    //   childrens: [
    //     {
    //       title: 'Relatório gerencial',
    //       url: '/app',
    //       children: true,
    //     },
    //     {
    //       title: 'Relatório de impacto',
    //       url: '/app',
    //       children: true,
    //     },
    //     {
    //       title: 'ROPA',
    //       url: '/app',
    //       children: true,
    //     },
    //   ],
    // },
  ]

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      paddingY={4}
      overflow="hidden"
    >
      <PerfectScrollbar>
        <List component={Box} width={256}>
          {menuItems.map((menuItem: MenuItemType) => (
            <ListItem key={menuItem.url}>
              <MenuItemButton menuItem={menuItem} />
            </ListItem>
          ))}
        </List>
      </PerfectScrollbar>
      <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
        <HelpBox />
      </Box>
    </Box>
  )
}

export default Items
