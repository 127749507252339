import { regex } from 'helpers'

import { PersonalFormSchemaType } from 'constants/settings'
import { StatusPaymentOrderReadingType } from 'constants/paymentOrder'

import constants from 'constants/index'

const passwordData = (data: PersonalFormSchemaType) => {
  return {
    currentPassword: data?.currentPassword,
    newPassword: data?.newPassword,
    confirmPassword: data?.confirmPassword,
  }
}

const personalData = (data: PersonalFormSchemaType) => {
  return {
    email: data?.email,
    name: data?.name,
    phone: regex.onlyNumbers(data?.phone),
  }
}

const chipStatusColor = (status?: StatusPaymentOrderReadingType) => {
  return (
    (status && constants.paymentOrder.READING_STATUS_CHIP_COLOR[status]) ||
    'default'
  )
}

const readingStatusTranslation = (status?: StatusPaymentOrderReadingType) => {
  return (
    (status && constants.paymentOrder.READING_STATUS_TRANSLATION[status]) || '-'
  )
}

export default {
  passwordData,
  personalData,
  chipStatusColor,
  readingStatusTranslation,
}
