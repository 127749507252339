import { useState } from 'react'

import { Box, FormHelperText, Grid, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { CardNumberInput } from 'components'

import { PaymentSectionType } from './paymentSection.types'

import { CreditCardBox, PaymentTextField } from './components'

import { theme } from 'theme'
import { CheckoutSchemaType } from 'constants/checkout/form'

import { isEmpty } from 'lodash'
import constants from 'constants/index'

const PaymentSection = ({ setBrand, creditCards }: PaymentSectionType) => {
  const anotherCreditCard = 'another'
  const [currentTab, setCurrentTab] = useState(creditCards?.[0]?.token)
  const {
    setValue,
    formState: { errors },
  } = useFormContext<CheckoutSchemaType>()

  const setCurrentCard = (token?: string) => {
    if (token && token !== anotherCreditCard) {
      setValue('typeCard', false)
      setValue('card.id', token)
    } else {
      setValue('typeCard', true)
      setValue('card.id', undefined)
    }

    setCurrentTab(token)
  }

  const showCreditCardForm =
    isEmpty(creditCards) || currentTab === anotherCreditCard

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="h6" color="primary">
        Dados do cartão
      </Typography>
      {!isEmpty(creditCards) && (
        <Grid container spacing={4}>
          {creditCards?.map((creditCard) => (
            <CreditCardBox
              creditCard={creditCard}
              isActive={currentTab === creditCard?.token}
              setCurrentCard={setCurrentCard}
            />
          ))}
          <CreditCardBox
            creditCard={{ token: anotherCreditCard, brand: 'Outro' }}
            isActive={currentTab === anotherCreditCard}
            setCurrentCard={setCurrentCard}
          />
          {!!errors?.typeCard && (
            <Grid item xs={12}>
              <FormHelperText error={!!errors?.typeCard}>
                {errors?.typeCard?.message}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      )}
      {showCreditCardForm && (
        <Grid container spacing={4}>
          <PaymentTextField
            item={
              constants.checkout.form.CONTROLLER_PAYMENT_CARD_OWNER_NAME_ITEM
            }
          />
          <Grid item xs={12} sm={8}>
            <CardNumberInput
              label="Número do cartão"
              inputStyle={{ backgroundColor: theme.palette.common.white }}
              permittedFlags={constants.cardFlags.STANDARD_FLAGS_ITEMS}
              setBrand={setBrand}
              labelOutside
              required
            />
          </Grid>
          {constants.checkout.form.CONTROLLER_PAYMENT_REST_ITEMS.map(
            (item, index) => (
              <PaymentTextField key={index} item={item} />
            )
          )}
        </Grid>
      )}
    </Box>
  )
}

export default PaymentSection
