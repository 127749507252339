import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'

import { visuallyHidden } from '@mui/utils'

import { useLocation, useNavigate } from 'react-router-dom'

import { CardPlan } from 'views/WelcomeSteps/components'
import SelectPlan from './components/SelectPlan'

import { useAuth } from 'hooks'
import { find, isEmpty, isNil } from 'lodash'

import { UpgradePlansType } from 'types/integrationPartner.types'

import { formatters, mountAdvantages } from 'helpers'

import {
  CheckoutPageStructure,
  CheckoutTrails,
  UpgradeForm,
} from './components'

import { CheckoutTrailId } from 'constants/checkout/trails'
import routes from 'constants/routes'

const UpgradeMain = () => {
  const [trailId, setTrailId] = useState<CheckoutTrailId | null | undefined>(
    null
  )
  const [errorTitle, setErrorTitle] = useState<string>()

  const location = useLocation()
  const navigate = useNavigate()
  const [upgradePlan, setUpgradePlan] = useState<UpgradePlansType>()
  const { userCompany, loaded: authLoaded } = useAuth()

  const findPlan = (id: number) =>
    find(upgradePlans, {
      plan: { id },
    }) as UpgradePlansType

  const loaded = authLoaded && !!userCompany?.id

  const upgradePlans = userCompany?.company?.integrationPartner?.upgradePlans
  const upgradePlanObject = findPlan(
    parseInt(location?.state?.planId)
  ) as UpgradePlansType

  const advantages = mountAdvantages(upgradePlan)

  const handleSetUpgradePlan = (planId?: string) => {
    if (planId) {
      setUpgradePlan(findPlan(parseInt(planId)))
    }
  }

  useEffect(() => {
    if (loaded && isEmpty(upgradePlans)) {
      navigate(routes.root)
    } else {
      setUpgradePlan(upgradePlanObject)
    }

    //eslint-disable-next-line
  }, [loaded])

  if (loaded && !upgradePlan) {
    return (
      <SelectPlan
        defaultOpen
        setSegment={handleSetUpgradePlan}
        segments={upgradePlans?.map((plan) => ({
          name: plan?.name,
          description: plan?.plan?.name,
          id: plan?.plan?.id,
        }))}
        variant="plano"
      />
    )
  }

  return (
    <>
      <CheckoutTrails
        trailId={trailId}
        setTrailId={setTrailId}
        style={isNil(trailId) ? visuallyHidden : undefined}
        errorTitle={errorTitle}
      />
      <CheckoutPageStructure>
        <Grid container alignItems="flex-start" spacing={4}>
          <Grid item xs={12} lg={3}>
            <CardPlan
              subtitle={
                'R$ ' + formatters.formatToCurrencyBrl(upgradePlan?.plan?.price)
              }
              advantages={advantages ?? []}
              title={upgradePlan?.name}
            />
          </Grid>
          <Grid item xs={12} lg={9}>
            <UpgradeForm
              upgradePlan={upgradePlan}
              setErrorTitle={setErrorTitle}
              setTrailId={setTrailId}
            />
          </Grid>
        </Grid>
      </CheckoutPageStructure>
    </>
  )
}

export default UpgradeMain
