import { reverse } from 'named-urls'
import { useNavigate } from 'react-router-dom'
import {
  Link,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { MenuButton, MUI, StandardTableCell } from 'components'

import { formatters, validates } from 'helpers'

import { CookieBannerType } from 'types/banner.types'

import routes from 'constants/routes'

const BannerTable = ({ banners }: { banners: CookieBannerType[] }) => {
  const navigate = useNavigate()

  const handleConfig = (bannerId: string | number) => {
    navigate(reverse(routes.banners.settings, { bannerId }))
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StandardTableCell $initial>ID</StandardTableCell>
            <StandardTableCell>Nome</StandardTableCell>
            <StandardTableCell>URL</StandardTableCell>
            <StandardTableCell align="center">Provedor</StandardTableCell>
            <StandardTableCell align="center">Acessos</StandardTableCell>
            <StandardTableCell>Planos</StandardTableCell>
            <StandardTableCell align="center">Status</StandardTableCell>
            <StandardTableCell align="center">Ações</StandardTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {banners?.map((banner) => (
            <TableRow key={banner?.id}>
              <StandardTableCell $initial>{banner?.id}</StandardTableCell>
              <StandardTableCell width={300} $maxWidth={300}>
                {banner?.name}
              </StandardTableCell>
              <StandardTableCell
                width={400}
                $maxWidth={400}
                tooltipTitle={banner?.url}
              >
                <Link
                  href={banner?.url}
                  target="_blank"
                  underline="none"
                  maxWidth="fit-content"
                >
                  {banner?.url}
                </Link>
              </StandardTableCell>
              <StandardTableCell align="center">Leavening</StandardTableCell>
              <StandardTableCell align="center">
                {formatters.numberToStringWithDots(banner?.requestsCount ?? 0)}
                <> / </>
                {formatters.numberToStringWithDots(
                  banner?.plan?.requestsLimit ?? 50000
                )}
              </StandardTableCell>
              <StandardTableCell>
                {banner?.plan?.name?.toUpperCase() ?? 'GRATUITO'}
              </StandardTableCell>
              <StandardTableCell align="center">
                <MUI.Chip
                  label={validates.banner.chipStatusLabel(banner?.status)}
                  variant="filled"
                  size="small"
                  color={validates.banner.chipStatusColor(banner?.status)}
                />
              </StandardTableCell>
              <StandardTableCell align="center">
                <MenuButton id={`banner-${banner?.id}`}>
                  <MenuItem onClick={() => handleConfig(banner?.id)}>
                    Configurações
                  </MenuItem>
                  {/* <MenuItem onClick={handleUpgrade}>Fazer Upgrade</MenuItem> */}
                </MenuButton>
              </StandardTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BannerTable
