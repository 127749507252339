import { theme } from 'theme'

const FREE_ACCESS_LIMT = '50.000'
const LIMITED_ACCESS_LIMT = '100.000'
const UNLITIMED_ACCESS_LIMT = '1.000.000'

const ACCESS_BY_COOKIE_PLAN = {
  free: `${FREE_ACCESS_LIMT} acessos`,
  limited: `${LIMITED_ACCESS_LIMT} acessos`,
  unlimited: `${UNLITIMED_ACCESS_LIMT} de acessos`,
}

const UPGRADE_BENEFITS_LIST = [
  {
    name: 'Banner de cookies ilimitado',
    iconColor: theme.palette.tertiary.main,
  },
  {
    name: 'Treinamentos com certificado',
    iconColor: theme.palette.support.main,
  },
  {
    name: 'Diagnóstico completo',
    iconColor: theme.palette.info.main,
  },
  {
    name: 'Medidas de segurança',
    iconColor: theme.palette.tertiary.main,
  },
  {
    name: 'Tratamento de ameaças',
    iconColor: theme.palette.support.main,
  },
  {
    name: 'Relatório de impacto',
    iconColor: theme.palette.info.main,
  },
]

const COOKIE_PLANS_ADVANTAGES = {
  free: {
    requestsLimit: FREE_ACCESS_LIMT,
    bannersLimit: 1,
    automaticScan: false,
  },
  limited: {
    requestsLimit: LIMITED_ACCESS_LIMT,
    bannersLimit: 1,
    automaticScan: false,
  },
  unlimited: {
    requestsLimit: UNLITIMED_ACCESS_LIMT,
    bannersLimit: 1,
    automaticScan: true,
  },
}

const BASE_DPOEASY_ADVANTAGES = [
  {
    name: 'Canal de Atendimento',
  },
  {
    name: 'Banner de Cookies',
  },
  {
    name: 'Políticas',
  },
  {
    name: 'Respostas Automáticas',
  },
]

const DPOEASY_ADVANTAGES = {
  free: [
    ...BASE_DPOEASY_ADVANTAGES,
    {
      name: 'Scanner de cookies',
    },
    {
      name: ACCESS_BY_COOKIE_PLAN['free'],
    },
    {
      name: '1 domínio',
    },
  ],
  limited: [
    ...BASE_DPOEASY_ADVANTAGES,
    {
      name: 'Scanner de cookies',
    },
    {
      name: ACCESS_BY_COOKIE_PLAN['limited'],
    },
    {
      name: '1 domínio',
    },
  ],
  unlimited: [
    ...BASE_DPOEASY_ADVANTAGES,
    {
      name: 'Scanner mensal de cookies',
    },
    {
      name: 'Atualização automática de políticas',
    },
    {
      name: ACCESS_BY_COOKIE_PLAN['unlimited'],
    },
    {
      name: '1 domínio',
    },
  ],
}

export default {
  BASE_DPOEASY_ADVANTAGES,
  UPGRADE_BENEFITS_LIST,
  DPOEASY_ADVANTAGES,
  COOKIE_PLANS_ADVANTAGES,
}
