import { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { isEmpty } from 'lodash'

import { SelectableButton } from 'components'
import DataLoading from './components/DataLoading'

import { useAuth, useJourney, usePrivacyChannel } from 'hooks'

import { TemplateType } from 'types/privacyChannel.types'

import constants from 'constants/index'

const Template = () => {
  const [showDataLoading, setShowDataLoading] = useState(false)
  const { handleNext, setNextDisabled } = useJourney()
  const { companyMaturity } = useAuth()
  const {
    defaultDataShares,
    defaultsDataTreatments,
    setCustomDataShares,
    setCustomDataTreatments,
    setDataDestroyStep,
    setDataUpdateStep,
    setDefaultDataShares,
    setDefaultsDataTreatments,
    setTemplates,
    templates,
  } = usePrivacyChannel()

  const resetTemplates = (templatesProp: TemplateType[]) => {
    templatesProp.map((template) => {
      template.active = false
    })
  }

  const resetAll = () => {
    defaultsDataTreatments.map((treatments) => {
      treatments.active = false
    })
    defaultDataShares.map((shares) => {
      shares.active = false
    })
    setDefaultsDataTreatments(defaultsDataTreatments)
    setDefaultDataShares(defaultDataShares)
    setCustomDataShares([])
    setCustomDataTreatments([])
    if (!companyMaturity?.dataUpdate) {
      setDataUpdateStep({
        description: undefined,
        updateTheirOwnData: undefined,
      })
    }

    if (!companyMaturity?.dataDestroy) {
      setDataDestroyStep({
        description: undefined,
        destroyTheirOwnData: undefined,
      })
    }
  }

  const handleClickTemplate = (index: number) => {
    const newTemplates = [...templates]

    if (index === 6) {
      resetAll()
      resetTemplates(newTemplates)
      newTemplates[index].active = !newTemplates[index].active
      defaultsDataTreatments.map((treatments) => {
        treatments.active = true
      })
      setDefaultsDataTreatments(defaultsDataTreatments)
      return setTemplates(newTemplates)
    }
    if (index !== 6 && newTemplates[6].active) {
      resetTemplates(newTemplates)
    }

    newTemplates[index].active = !newTemplates[index].active
    setTemplates(newTemplates)
  }

  const chosenNameTemplates = () => {
    const arrayChosenTemplates = templates.filter(
      (template) => template.active === true
    )

    const nameTemplates = arrayChosenTemplates.map(
      (template) => template['name']
    )

    return nameTemplates
  }

  const namesTemplates = chosenNameTemplates()

  const {
    DATA_TREATMENTS_DEFAULTS,
    DATA_TREATMENTS_CUSTOM,
    DATA_SHARE_DEFAULT,
    DATA_SHARE_CUSTOM,
    TEMPLATE_PLATFORMS,
    NONE,
  } = constants.privacyChannelConfigurationSteps

  const selectedTemplates = () => {
    const templates = namesTemplates.map((name) => {
      return TEMPLATE_PLATFORMS.find((template) => template.name === name)
    })
    return templates
  }

  useEffect(() => {
    if (showDataLoading) return

    if (isEmpty(selectedTemplates())) {
      setNextDisabled(true)
    } else {
      setNextDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplates])

  const uniqArrayObject = (objects: TemplateType[]) => {
    return objects.filter(
      (obj, index, self) =>
        index ===
        self.findIndex((o) => o.active === obj.active && o.name === obj.name)
    )
  }

  const checkTemplateDataTreatment = () => {
    DATA_TREATMENTS_DEFAULTS.map((defaultDataTreatment) => {
      defaultDataTreatment.active = false
    })

    DATA_TREATMENTS_CUSTOM.length = 0

    selectedTemplates().map((selectedTemplate) => {
      selectedTemplate?.template?.dataTreatments.map(
        (templateDataTreatment) => {
          const index = DATA_TREATMENTS_DEFAULTS.findIndex(
            (defaultDataTreatment) => {
              return templateDataTreatment === defaultDataTreatment.name
            }
          )

          if (index === -1) {
            DATA_TREATMENTS_CUSTOM.push({
              name: templateDataTreatment,
              active: true,
            })
          } else {
            DATA_TREATMENTS_DEFAULTS[index].active = true
          }
        }
      )
    })

    const uniqDataTreatmentCustom = uniqArrayObject(DATA_TREATMENTS_CUSTOM)

    setCustomDataTreatments(uniqDataTreatmentCustom)
    setDefaultsDataTreatments(DATA_TREATMENTS_DEFAULTS)
  }

  const checkTemplateDataShare = () => {
    DATA_SHARE_DEFAULT.map((defaultDataShare) => {
      defaultDataShare.active = false
    })

    DATA_SHARE_CUSTOM.length = 0

    selectedTemplates().map((selectedTemplate) => {
      selectedTemplate?.template?.dataShare.map((templateDataShare) => {
        const index = DATA_SHARE_DEFAULT.findIndex((defaultDataShare) => {
          return templateDataShare === defaultDataShare.name
        })

        if (index === -1) {
          DATA_SHARE_CUSTOM.push({
            name: templateDataShare,
            active: true,
          })
        } else {
          DATA_SHARE_DEFAULT[index].active = true
        }
      })
    })

    const uniqDataShareCustom = uniqArrayObject(DATA_SHARE_CUSTOM)

    setCustomDataShares(uniqDataShareCustom)
    setDefaultDataShares(DATA_SHARE_DEFAULT)
  }

  const checkTemplateDataUpdate = () => {
    const isUpdateTrue = selectedTemplates().some((selectedTemplate) => {
      return selectedTemplate?.template?.dataUpdate.updateTheirOwnData === true
    })

    const allDescriptions: string[] = []

    if (isUpdateTrue) {
      selectedTemplates().map((selectedTemplate) => {
        const description = selectedTemplate?.template?.dataUpdate?.description
        if (selectedTemplates().length > 1) {
          const nameTemplate = selectedTemplate?.template?.nameTemplate

          if (description) {
            allDescriptions.push(`${nameTemplate}:\n${description}`)
          }
        } else {
          if (description) {
            allDescriptions.push(description)
          }
        }
      })

      const allDescriptionsUniq = allDescriptions.filter(
        (item, index) => allDescriptions.indexOf(item) === index
      )

      setDataUpdateStep({
        description: allDescriptionsUniq.join('\n\n'),
        updateTheirOwnData: true,
      })
    } else {
      setDataUpdateStep({
        description: '',
        updateTheirOwnData: undefined,
      })
    }
  }

  const checkTemplateDataDestroy = () => {
    const isDestroyTrue = selectedTemplates().some((selectedTemplate) => {
      return (
        selectedTemplate?.template?.dataDestroy.destroyTheirOwnData === true
      )
    })

    const allDescriptions: string[] = []

    if (isDestroyTrue) {
      selectedTemplates().map((selectedTemplate) => {
        const description = selectedTemplate?.template?.dataDestroy?.description

        if (selectedTemplates().length > 1) {
          const nameTemplate = selectedTemplate?.template?.nameTemplate

          if (description) {
            allDescriptions.push(`${nameTemplate}:\n${description}`)
          }
        } else {
          if (description) {
            allDescriptions.push(description)
          }
        }
      })

      const allDescriptionsUniq = allDescriptions.filter(
        (item, index) => allDescriptions.indexOf(item) === index
      )

      setDataDestroyStep({
        description: allDescriptionsUniq.join('\n\n'),
        destroyTheirOwnData: true,
      })
    } else {
      setDataDestroyStep({
        description: '',
        destroyTheirOwnData: undefined,
      })
    }
  }

  const noOption = () => {
    return namesTemplates[0] === NONE
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (noOption()) return handleNext()

    resetAll()
    checkTemplateDataTreatment()
    checkTemplateDataShare()
    checkTemplateDataUpdate()
    checkTemplateDataDestroy()
    setShowDataLoading(true)
  }

  return (
    <>
      {showDataLoading ? (
        <DataLoading />
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              color="primary"
              fontWeight={700}
              textAlign="center"
            >
              Configurar Canal de Atendimento
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mb={6}>
              <Typography
                variant="h6"
                fontWeight={400}
                textAlign="center"
                color="primary"
                fontFamily="Raleway"
              >
                Temos integração com todas as plataformas a seguir.&nbsp;
                <strong>
                  Selecione as que você utiliza para configurarmos
                  automaticamente o seu canal de atendimento.
                </strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              {templates.map((template, index) => (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <SelectableButton
                    name={template.name}
                    image={template.image}
                    active={template.active}
                    onClick={() => handleClickTemplate(index)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <form id="form-register" onSubmit={onSubmit}></form>
        </Grid>
      )}
    </>
  )
}

export default Template
