import * as yup from 'yup'
import testValidator from 'helpers/validates/testValidator'

const schema = yup.object({
  planId: yup.string().required(),
  typeCard: yup.boolean().required('Escolha um modo de pagamento'),
  address: yup.object().shape({
    street: yup.string().required(),
    number: yup.string().required(),
    complement: yup.string().max(100),
    neighborhood: yup.string().required(),
    postalCode: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
  }),
  card: yup.object().shape({
    id: yup.string(),
    name: yup
      .string()
      .test(
        'conditionally-required',
        'Preencha esse campo',
        testValidator('typeCard')
      ),
    number: yup
      .string()
      .test(
        'conditionally-required',
        'Preencha esse campo',
        testValidator('typeCard')
      ),
    expiration: yup
      .string()
      .test(
        'conditionally-required',
        'Preencha esse campo',
        testValidator('typeCard')
      ),
    cvv: yup
      .string()
      .test(
        'conditionally-required',
        'Preencha esse campo',
        testValidator('typeCard')
      ),
  }),
  recaptcha: yup.string().required('Complete o recaptcha'),
})

export default schema
