import { useState } from 'react'
import { isEmpty } from 'lodash'
import { Box, Container } from '@mui/material'

import { FilterButton, NoData, PageHead } from 'components'
import { CardFaqs, FaqsFilter, SearchBox } from './components'

import { useFetch, useFilter } from 'hooks'

import { FaqsType } from 'types/faqs.types'

import constants from 'constants/index'
import service from 'service'

const Main = () => {
  const [searchInput, setSearchInput] = useState('')
  const [valueInput, setValueInput] = useState('')
  const [filteredResults, setFilteredResults] = useState<FaqsType[]>([])

  const filter = useFilter()

  const { response, loading } = useFetch(
    service.dponet.faqs.get,
    {
      params: {
        kind: constants.faqs.KINDS.FAQ,
        draft: false,
        visualization: constants.faqs.VISUALIZATION.CE,
        minimal: true,
        perPage: 10000000,
        active: true,
        ...filter.filters,
      },
    },
    [filter.filters]
  )

  const faqsDocumentations = response?.data?.faqsDocumentations

  return (
    <Container component={Box} paddingY={12}>
      <PageHead
        name="Central de Ajuda"
        actionButton={<FilterButton setDrawerOpen={filter.setDrawerOpen} />}
      />
      {loading || isEmpty(faqsDocumentations) ? (
        <NoData title="Nenhuma FAQ foi encontrada." />
      ) : (
        <>
          <SearchBox
            faqs={faqsDocumentations}
            searchInput={searchInput}
            setFilteredResults={setFilteredResults}
            setSearchInput={setSearchInput}
            setValueInput={setValueInput}
            valueInput={valueInput}
          />
          {!loading && isEmpty(searchInput) && isEmpty(valueInput) ? (
            <CardFaqs faqs={faqsDocumentations} />
          ) : (
            <CardFaqs faqs={filteredResults} />
          )}
        </>
      )}

      <FaqsFilter filter={filter} />
    </Container>
  )
}

export default Main
