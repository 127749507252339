import { useParams } from 'react-router-dom'
import { Box, Paper, Typography } from '@mui/material'
import DOMPurify from 'dompurify'

import {
  DescriptionFaq,
  StandardPageStructure,
  LoadingFeedback,
  MUI,
  PageHead,
} from 'components'

import { useFetch } from 'hooks'

import service from 'service'

const Show = () => {
  const { faqId } = useParams()

  const { response, loading } = useFetch(service.dponet.faqs.get, {
    faqsDocumentationId: faqId,
  })

  const faq = response?.data?.faqsDocumentation

  const descriptionSanitizer = DOMPurify.sanitize(faq?.description, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  })

  return (
    <StandardPageStructure>
      <PageHead name="FAQ" />
      <Paper variant="outlined">
        <LoadingFeedback open={loading} />
        <Box width="100%" m={2}>
          <MUI.Chip
            label={
              <Typography color="white" variant="button">
                {faq?.faqsDocumentationsCategory?.name.toUpperCase()}
              </Typography>
            }
            color="tertiary"
            $borderRadius={16}
          />
        </Box>
        <Box mt={1}>
          <Typography textAlign="center" variant="h5">
            {faq?.title}
          </Typography>
          <Typography textAlign="center" variant="h6">
            {faq?.subtitle}
          </Typography>
        </Box>
        <DescriptionFaq
          m={5}
          textOverflow="ellipsis"
          overflow="hidden"
          dangerouslySetInnerHTML={{
            __html: descriptionSanitizer,
          }}
        />
      </Paper>
    </StandardPageStructure>
  )
}

export default Show
