import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material'

import { MUI } from 'components'
import { TitleFaq } from './components'

import { formatters } from 'helpers'
import routes from 'constants/routes'

import { CardFaqsType } from './CardFaqs.types'

const CardFaqs = ({ faqs }: CardFaqsType) => {
  const navigate = useNavigate()

  const seeMoreButton = (faqId: number) => {
    navigate(
      reverse(routes.faqs.show, {
        faqId: faqId,
      })
    )
  }

  return (
    <>
      {faqs?.map((faq) => (
        <Card key={faq?.id} component={Box} mt={5}>
          <CardContent>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" gap={2}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <MUI.Chip
                    label={
                      <Typography
                        color="white"
                        variant="overline"
                        fontWeight={500}
                      >
                        {faq?.categoryName?.toUpperCase()}
                      </Typography>
                    }
                    $borderRadius={16}
                    color="tertiary"
                  />
                  <Typography variant="body2" m={2}>
                    {formatters.date.ptBrFormatDate(faq?.updatedAt)}
                  </Typography>
                </Box>
                <Tooltip title={faq?.title} placement="top">
                  <TitleFaq variant="body1" fontWeight={600}>
                    {faq?.title}
                  </TitleFaq>
                </Tooltip>
              </Box>
              <MUI.Button
                startIcon={<PlusCircleIcon size={20} />}
                variant="outlined"
                onClick={() => seeMoreButton(faq?.id)}
                $borderRadius={20}
                $border="1px solid"
              >
                <Typography variant="body2" fontWeight={500}>
                  VER MAIS
                </Typography>
              </MUI.Button>
            </Box>
            <Box p={4}>
              <Tooltip title={faq?.subtitle} placement="top">
                <TitleFaq variant="body2" fontWeight={500}>
                  {faq?.subtitle}
                </TitleFaq>
              </Tooltip>
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  )
}

export default CardFaqs
