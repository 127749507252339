import * as yup from 'yup'

const schema = yup
  .object()
  .shape({
    street: yup.string().required(),
    number: yup.string().required(),
    neighborhood: yup.string().required(),
    postalCode: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    country: yup.string().required(),
    complement: yup.string(),
  })
  .required()

export type WelcomePlanFormSchemaType = yup.InferType<typeof schema>

export type WelcomePlanFormControlType = keyof WelcomePlanFormSchemaType

export default schema
