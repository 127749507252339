import { Grid, Typography } from '@mui/material'

import { HighlightText } from 'components'

import { usePrivacyChannel } from 'hooks'

const Finish = () => {
  const { dataUpdateStep, dataDestroyStep, finishJourney } = usePrivacyChannel()

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    finishJourney()
  }

  const calculateAutomaticAnswers = () => {
    return (
      7 +
      Number(dataDestroyStep?.destroyTheirOwnData) +
      Number(dataUpdateStep?.updateTheirOwnData)
    )
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          color="primary"
          fontWeight={700}
          textAlign="center"
        >
          Pronto! Configuramos o seu canal de atendimento.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight={400}
          color="primary"
          textAlign="center"
        >
          Com as suas informações, consegui automatizar&nbsp;
          <HighlightText>
            {calculateAutomaticAnswers()} de 10 respostas
          </HighlightText>
          &nbsp; para os titulares de dados.
        </Typography>
        <Typography
          variant="h6"
          fontWeight={400}
          color="primary"
          textAlign="center"
        >
          Caso você queira corrigir ou alterar alguma informação, acesse o Canal
          de Atendimento, e clique em Editar Respostas.
        </Typography>
        <form id="form-register" onSubmit={onSubmit}></form>
      </Grid>
    </Grid>
  )
}

export default Finish
