import { Fragment } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, TextField } from '@mui/material'

import { BasicDialog } from 'components'

import BannerInfo from '../BannerInfo'

import { formatters } from 'helpers'

import { useSnackbar } from 'hooks'

import { NewBannerModalType } from './newBannerModal.types'

import schema, { NewBannerModalSchemaType } from './schema'
import constants from 'constants/index'
import service from 'service'

const NewBannerModal = ({
  open,
  onClose,
  setLoading,
  refresh,
}: NewBannerModalType) => {
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewBannerModalSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      url: '',
      status: constants.banner.INCOMPLETE_STATUS_ID,
    },
  })

  const onSubmit = async (data: NewBannerModalSchemaType) => {
    try {
      setLoading(true)

      await service.dpoeasy.banner.create({ ...data })
      snackbar.open({
        message: 'Banner adicionado com sucesso!',
        variant: 'success',
      })

      reset({})
      refresh()
      handleClose()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <BasicDialog
      open={open}
      onClose={handleClose}
      title="Novo banner de cookies"
      maxWidth="md"
      dialogActions={
        <Fragment>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="new-banner-modal-form"
          >
            Adicionar
          </Button>
        </Fragment>
      }
    >
      <Box
        component="form"
        id="new-banner-modal-form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={4}
      >
        <BannerInfo />
        {constants.banner.NEW_BANNER_MODAL_CONTROL_ITEMS.map((item, index) => (
          <Controller
            key={index}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.[item?.controlName]}
                helperText={errors?.[item?.controlName]?.message}
                type="text"
                label={item?.label}
                fullWidth
              />
            )}
            control={control}
            name={item?.controlName}
          />
        ))}
      </Box>
    </BasicDialog>
  )
}

export default NewBannerModal
