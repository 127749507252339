import { ChangeEvent, useRef } from 'react'
import { Clock as ClockIcon, Paperclip as PaperclipIcon } from 'react-feather'
import { isNil } from 'lodash'

import CancelIcon from '@mui/icons-material/Cancel'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Avatar,
  Box,
  Button,
  colors,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'

import { ButtonGroup } from 'components'
import {
  AwaitingConfirmationAlert,
  ConfirmTitularData,
  StyledAttachmentButton,
  TicketMessageBox,
} from './components'

import { useCurrentDateTime, useMediaQuery } from 'hooks'
import { TicketTextBoxType } from './ticketTextBox.types'

import { avatarLetters, fileControl, validates } from 'helpers'
import { theme } from 'theme'

import constants from 'constants/index'

const TicketTextBox = ({
  refresh,
  setStates,
  ticket,
  useForm,
  user,
  useStates,
}: TicketTextBoxType) => {
  const { setSelectedFiles, setStatusButtonGroup } = setStates
  const { selectedFiles, statusButtonGroup } = useStates
  const { register } = useForm

  const mediaQuery = useMediaQuery()
  const currentDateTime = useCurrentDateTime()
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const isSmall = mediaQuery.down.sm
  const isExtraSmall = mediaQuery.down.sm

  const isConcluded = ticket?.status?.id === constants.ticket.FINISHED_TICKET_ID
  ticket?.status?.id === constants.ticket.AWAITING_CONFIRMATION_IDENTITY_ID
  const awaitingTitularValidationStatus =
    ticket?.status?.id === constants.ticket.AWAITING_CONFIRMATION_IDENTITY_ID
  const awaitingTitularConfirmation =
    ticket?.status?.id === constants.ticket.WAITING_CONFIRMATION_TICKET_ID
  const awaitingCompanyReply =
    ticket?.status?.id === constants.ticket.AWAITING_COMPANY_REPLY_ID

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    fileControl.handleFileChange({
      event,
      fileInputRef,
      files: selectedFiles,
      setFiles: setSelectedFiles,
    })
  }

  const handleFileRemove = (fileName: string) => {
    fileControl.handleFileRemove({
      fileName,
      files: selectedFiles,
      setFiles: setSelectedFiles,
    })
  }

  const mustDisabledButton = (statusButtonGroup: number) => {
    return (
      !constants.ticket.PERMITTED_STATUSES.includes(statusButtonGroup) &&
      statusButtonGroup !== ticket?.status?.id
    )
  }

  return (
    <Box mt={6}>
      {awaitingTitularValidationStatus && (
        <ConfirmTitularData ticket={ticket} refresh={refresh} />
      )}
      {awaitingTitularConfirmation && (
        <AwaitingConfirmationAlert text="Essa solicitação está aguardando a confirmação do solicitante via e-mail." />
      )}
      {awaitingCompanyReply && (
        <AwaitingConfirmationAlert text="Responda à solicitação. Confira o tipo de pedido para fornecer informações compatíveis com os dados que existem na sua organização." />
      )}

      <Paper component={Box} variant="outlined" mt={2}>
        <Box display="flex" flexDirection="column" px={3} mt={3.5} gap={4}>
          <TicketMessageBox useForm={useForm} selectedFiles={selectedFiles} />
          <PerfectScrollbar>
            <Box display="flex" flexDirection="row">
              {selectedFiles?.map((file) => (
                <Box key={file?.name} mr={2} mb={2}>
                  <StyledAttachmentButton type="button">
                    {file?.name}
                    <Typography
                      color={colors.grey[400]}
                      onClick={() => handleFileRemove(file?.name)}
                      display="flex"
                      pl={2}
                      noWrap
                    >
                      <CancelIcon />
                    </Typography>
                  </StyledAttachmentButton>
                </Box>
              ))}
            </Box>
          </PerfectScrollbar>
        </Box>
        <Divider />
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          p={3}
        >
          <Grid item xs={12} sm={4}>
            <Box
              display="flex"
              gap={4}
              justifyContent={isSmall ? 'center' : 'start'}
            >
              <Avatar alt={user?.name}>{avatarLetters(user?.name)}</Avatar>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Tooltip title={user?.name} placement="top">
                  <Typography
                    fontWeight={700}
                    color={theme.palette.neutral.dark}
                    maxWidth={180}
                    noWrap
                  >
                    {user?.name || 'Não informado'}
                  </Typography>
                </Tooltip>
                <Typography
                  variant="caption"
                  color={theme.palette.neutral.main}
                >
                  {currentDateTime}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Box display="flex" justifyContent={isSmall ? 'center' : 'start'}>
              <Button variant="outlined" component="label" color="inherit">
                <Box display="flex">
                  <PaperclipIcon size={15} />
                </Box>
                ANEXAR
                <Box display="none">
                  {register && (
                    <input
                      {...register('attachment')}
                      ref={fileInputRef}
                      id="attachment"
                      name="attachment"
                      type="file"
                      multiple
                      accept={constants.validations.ALL_FILE_TYPES}
                      onChange={handleFileChange}
                    />
                  )}
                </Box>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} minWidth={!isExtraSmall ? 300 : undefined}>
            {statusButtonGroup && (
              <ButtonGroup
                options={constants.ticket.TICKET_STATUS_OPTIONS.filter(
                  (item) => item.id !== null
                )}
                title="ENVIAR COMO"
                buttonGroup={statusButtonGroup}
                setButtonGroup={setStatusButtonGroup}
                type="submit"
                minTitleWidth={100}
                disabled={mustDisabledButton(statusButtonGroup)}
                fullWidth
                ticketStatusId={ticket?.status?.id}
              />
            )}
          </Grid>
        </Grid>

        {!isNil(ticket?.daysToDeadlineDate) && !isConcluded && (
          <>
            <Divider />
            <Box pt={3} px={3} pb={3}>
              <Box
                display="flex"
                bgcolor={colors.blueGrey[700]}
                borderRadius={1}
                alignItems="start"
                gap={2}
                p={2}
              >
                <ClockIcon color="white" size={16} />
                <Typography color="white" variant="body2">
                  Prazo:&nbsp;
                  <strong>
                    {validates.ticket.deadlineTranslation(
                      ticket?.daysToDeadlineDate
                    )}
                  </strong>
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  )
}

export default TicketTextBox
