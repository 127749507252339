import { ChangeEvent } from 'react'

import { Grid, Typography } from '@mui/material'

import { useFormContext } from 'react-hook-form'

import { TextField } from '..'

import { WelcomePlanFormSchemaType } from '../../schema'
import { formatters } from 'helpers'

const FormCard = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<WelcomePlanFormSchemaType>()

  const handleFormat = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: 'card' | 'expiraton' | 'cvv'
  ) => {
    let value = e?.target?.value
    switch (field) {
      case 'card':
        value = formatters.cardFlag.number(e?.target?.value)
        break
      case 'cvv':
        value = e?.target?.value?.slice(0, 4).toUpperCase()
        break
      case 'expiraton':
        value = e.target.value = formatters.cardFlag.expiringDate(
          e?.target?.value
        )
        break
      default:
        break
    }

    e.target.value = value
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="700" color="primary">
          Dados do cartão
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nome impresso no cartão"
              name="card.name"
              type="text"
              error={errors?.card?.name}
              control={control}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              onChange={(e) => handleFormat(e, 'card')}
              label="Número do cartão"
              name="card.number"
              type="text"
              error={errors?.card?.number}
              control={control}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
              error={errors?.card?.expiration}
              onChange={(e) => handleFormat(e, 'expiraton')}
              label="Validade"
              type="text"
              name="card.expiration"
              control={control}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
              error={errors?.card?.cvv}
              onChange={(e) => handleFormat(e, 'cvv')}
              label="CVV"
              type="text"
              name="card.cvv"
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FormCard
