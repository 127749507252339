const FAQ = 1
const DOCUMENTATION = 2
const LAST_UPDATES = 3

const KINDS = {
  FAQ: 'faq',
  DOCUMENTATION: 'documentation',
}

const VISUALIZATION = {
  c: 'collaborator',
  CC: 'collaborator_and_client',
  CA: 'collaborator_and_advisor',
  CE: 'collaborator_and_easy',
}

const KINDS_LABELS = {
  F: 'FAQ',
  D: 'Documentação',
}

const CATEGORIES_TYPE = {
  faq: 'faq_category',
  doc: 'doc_category',
}

const faqsDocumentations = {
  CATEGORIES_TYPE,
  DOCUMENTATION,
  FAQ,
  KINDS_LABELS,
  KINDS,
  LAST_UPDATES,
  VISUALIZATION,
}

export default faqsDocumentations
