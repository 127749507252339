import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const solicitation = async ({ ...data }) => {
  return await dponetAPI.post('/tomticket_intermediator', {
    tomticket: data,
  })
}

export default {
  solicitation,
}
