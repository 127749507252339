import { Controller } from 'react-hook-form'
import {
  CircularProgress,
  FormLabel,
  InputAdornment,
  TextField as TextFieldMUI,
  Typography,
} from '@mui/material'

import { theme } from 'theme'

import { TextFieldType } from './textField.types'

const TextField = ({
  control,
  name,
  label,
  onChange,
  error,
  type = 'text',
  loading = false,
  disabled = false,
}: TextFieldType) => {
  return (
    <>
      <FormLabel title={label} error={!!error}>
        <Typography fontWeight="400" color={error ? 'red' : 'primary'}>
          {label}
        </Typography>
      </FormLabel>
      <Controller
        render={({ field }) => (
          <TextFieldMUI
            {...field}
            error={!!error}
            {...(!!onChange && {
              onChange: (e) => {
                onChange(e)
                field.onChange(e)
              },
            })}
            helperText={<>{error?.message}</>}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: disabled
                  ? theme.palette.disabled.background
                  : theme.palette.background.paper,
              },
            }}
            InputProps={{
              ...(loading && {
                endAdornment: (
                  <InputAdornment position="end">
                    <CircularProgress size={22} />
                  </InputAdornment>
                ),
              }),
            }}
            type={type}
            disabled={disabled}
            fullWidth
          />
        )}
        name={name}
        control={control}
      />
    </>
  )
}

export default TextField
