import { useNavigate } from 'react-router-dom'
import {
  ArrowForward as ArrowIcon,
  HexagonOutlined as HexagonIcon,
} from '@mui/icons-material'

import { Dialog, DialogActions, DialogContent } from '@mui/material'

import { UpgradeModalType } from './upgradeModal.types'

import { HexagonStructure, MUI } from 'components'
import DPOnet from 'components/Svg/DPOnet'

import { theme } from 'theme'
import constants from 'constants/index'
import routes from 'constants/routes'

const UpgradeModal = ({ open, onClose }: UpgradeModalType) => {
  const navigate = useNavigate()

  const handleClose = () => {
    onClose()
  }

  const handleClick = () => {
    navigate(routes.checkout.all)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <HexagonStructure
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        px={16}
        py={12}
        gap={16}
        bgcolor={theme.palette.primary.main}
      >
        <MUI.Typography
          variant="h4"
          align="center"
          color={theme.palette.common.white}
        >
          Adequação completa e com banner de cookies ilimitado
        </MUI.Typography>
        <DPOnet fill={theme.palette.common.white} width="35%" />
      </HexagonStructure>

      <DialogContent>
        <>
          <MUI.Typography variant="h6" color="primary">
            Faça o upgrade para a DPOnet!
          </MUI.Typography>
          <MUI.Typography variant="body1" my={4}>
            Como usuário da DPO Easy você está aproveitando uma versão básica
            para dar os primeiros passos em direção à adequação. Faça o upgrade
            para a DPOnet e tenha acesso à plataforma completa, que te
            possibilitará alcançar a conformidade real.
          </MUI.Typography>
        </>
        <MUI.Box $gap={4} display="flex" flexDirection="column">
          {constants.plan.UPGRADE_BENEFITS_LIST.map((benefit, index) => (
            <MUI.Box $gap={2} display="flex" key={index}>
              <HexagonIcon sx={{ color: benefit.iconColor }} />
              <MUI.Typography variant="body1" color="primary" fontWeight="bold">
                {benefit.name}
              </MUI.Typography>
            </MUI.Box>
          ))}
        </MUI.Box>
      </DialogContent>
      <DialogActions>
        <MUI.Button
          variant="contained"
          endIcon={<ArrowIcon />}
          onClick={handleClick}
          $smDown={{ width: '100%' }}
        >
          Faça upgrade para o DPOnet
        </MUI.Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpgradeModal
